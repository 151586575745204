<script>
import CONFIG from '@root/config'

export default {
    methods: {
        downloadAssets (payload, url, pollingUrl, title, jobName) {
            this.loading = true
            this.addJob(jobName)
            return this.$api.post(url, payload).then(({ data }) => {
                if (data.data.url) {
                    this.startDownload(data.data.url, jobName)
                } else if (data.data.token) {
                    this.$root.$emit('notification:global', {
                        message: `Download is being prepared. The download will start automatically when ready.`,
                    })

                    const builtPollingUrl = pollingUrl.replace('{token}', data.data.token)
                    this.startDownloadPolling(payload, jobName, 'CSV', builtPollingUrl, title)
                }
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Downloading failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(jobName)
            })
        },
        startDownloadPolling (payload, jobName, action, url, title) {
            this.$store.dispatch('tasks/addTask', {
                title: `${title} ${action}`,
                interval: CONFIG.CHECK_TOKEN_RESPONSE_TIMEOUT,
                attempts: CONFIG.CHECK_TOKEN_RESPONSE_ATTEMPTS,
                retryOnFail: true,
                onComplete: data => {
                    this.startDownload(data.data.url, jobName)
                },
                onPoll: () => {
                    return this.$api.post(url, payload)
                },
                handleResult: (result) => {
                    if (result.data.length === 0) {
                        return Promise.resolve(null)
                    }
                    return Promise.resolve(result.data)
                },
            })
        },
        startDownload (url, jobName) {
            this.$bus.$emit('DOWNLOAD_URL_RESOLVED', url)
            this.$root.$emit('notification:global', {
                message: `Download started.`,
            })
            this.finishJob(jobName)
        },
    },
}
</script>
