<template>
    <mercur-card v-if="isAllowedTo('SupplierCentral/getGeneratedProductsList')" class="full-height-layout fill mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            {{isVirtualSupplierManagement ? 'Product Templates' : 'Products'}}
            <template slot="actions">
                <mercur-button v-if="isAllowedTo('SupplierCentral/saveProductConfiguration')" :to="addProductRoute" class="btn btn-raised btn-yellow text-uppercase">
                    <i class="fas fa-plus" />
                    Add new product {{isVirtualSupplierManagement ? 'template' : ''}}
                </mercur-button>
                <mercur-button
                    class="btn btn-yellow text-uppercase"
                    @click.native="downloadAssetsWrapper(false)"
                >
                    <i class="fas fa-plus"></i>
                    Export for Fast Editor
                </mercur-button>
                <mercur-button
                    class="btn btn-grey-lighter text-uppercase"
                    @click.native="downloadAssetsWrapper(true)"
                >
                    <i class="fas fa-plus"></i>
                    Export for Fast Editor (with blanks)
                </mercur-button>
            </template>
        </grid-header>
        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            :quickSearch="filters.search"
        ></data-table>
        <mercur-dialog :is-open.sync="cloneProductDialogShow">
            <div slot="header">
                <h3 class="font-weight-normal mt-0">Clone product to supplier</h3>
            </div>
            <div class="px-1" v-if="allSuppliers">
                <pretty-select
                    placeholder="Supplier"
                    label="supplierName"
                    :options="allSuppliers"
                    v-model="cloneProductSupplierId"
                    :reduce="supplier => supplier.supplierId"
                ></pretty-select>
                <p>Please make sure you complete the product configurator steps after clicking the 'Clone' button</p>
            </div>
            <div slot="footer">
                <mercur-button class="btn btn-primary" @click="cloneProductDialogShow = false">Close</mercur-button>
                <mercur-button class="btn btn-yellow" :disabled="!cloneProductSupplierId" target="_blank" :to="cloneProductRoute">Clone</mercur-button>
            </div>
        </mercur-dialog>
    </mercur-card>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import DataTable from '@/components/DataTable'
import PrettySelect from '../../../components/utils/PrettySelect'
import DownloadMixin from '@/components/DownloadMixin'

export default {
    name: 'ProductsOverview',
    components: { PrettySelect, DataTable, GridHeader },
    mixins: [DownloadMixin],
    data () {
        return {
            uploadCsvPopupActive: false,
            cloneProductDialogShow: false,
            cloneProductSupplierId: this.supplierId,
            cloneProductConfigurationId: null,
            allSuppliers: null,
            options: {
                columns: {
                    'Name': {
                        field: 'productConfigurationName',
                        sortable: true,
                        link: this.isAllowedTo('SupplierCentral/getProductConfigurationById') ? (value, data) => {
                            return {
                                name: 'SupplierProductView',
                                params: {
                                    supplierId: this.supplierId,
                                    productConfigurationId: data.productConfigurationId,
                                },
                            }
                        } : null,
                    },
                    'Published': {
                        field: 'productConfigurationStatus',
                        statusChip: true,
                    },
                    'Task (status)': {
                        field: 'taskStatus',
                        statusChip: true,
                        cellRendererParams ({ data }) {
                            return {
                                text: `${data.taskType} (${data.taskStatus})`,
                                statusChipSize: 'small',
                            }
                        },
                    },
                    'Created': {
                        field: 'dateCreated',
                        sortable: true,
                    },
                    'Updated': {
                        field: 'dateUpdated',
                        sortable: true,
                    },
                },

                actions: [
                    {
                        icon: 'fas fa-clone',
                        text: 'Clone',
                        hide: () => {
                            return this.isSupplier || !this.isAllowedTo('SupplierCentral/saveProductConfiguration')
                        },
                        tooltipText: 'Clone this product to another supplier',
                        onClick: ({ data }) => {
                            this.openProductCloneDialog(data)
                        },
                    },
                    {
                        icon: 'fas fa-plus',
                        text: 'Create Comercial Product',
                        hide: () => {
                            return this.isSupplier || !this.isAllowedTo('SupplierCentral/saveProductConfiguration')
                        },
                        tooltipText: 'Create Comercial Product from this template',
                        to: (data) => {
                            return {
                                name: 'CommercialProductAdd',
                                params: {
                                    supplierId: this.supplierId,
                                    productConfigurationId: data.data.productConfigurationId,
                                },
                            }
                        },
                    },
                ],

                quickSearchColumns: ['productConfigurationName'],
                sortModel: [{
                    colId: 'dateUpdated',
                    sort: 'desc',
                }],
            },
            filters: {
                search: '',
            },
        }
    },
    methods: {
        openProductCloneDialog (data) {
            this.cloneProductConfigurationId = data.productConfigurationId
            this.cloneProductDialogShow = true
        },
        downloadAssetsWrapper (withBlanks) {
            const url = CONFIG.API.ROUTES.PRODUCTS.TASKS.DOWNLOAD
            let payload = {
                supplierId: this.supplierId,
            }
            if (withBlanks) {
                payload = {
                    ...payload,
                    withBlanks: true,
                }
            }
            const jobName = [url, ...Object.values(payload).map(JSON.stringify)].join('')
            this.downloadAssets(payload, url, CONFIG.API.ROUTES.PRODUCTS.TASKS.CHECK_TOKEN_RESPONSE, 'Download Fast Editor', jobName)
        },
    },
    computed: {
        isVirtualSupplierManagement () {
            return !this.isSupplier && this.supplierId === this.$store.state.auth.user.supplierId
        },
        url () {
            return CONFIG.API.ROUTES.SUPPLIERS.PRODUCTS.replace('{supplierId}', this.supplierId)
        },
        addProductRoute () {
            return {
                name: 'SupplierProductsAdd',
                params: {
                    supplierId: this.supplierId,
                },
            }
        },
        cloneProductRoute () {
            return {
                name: 'SupplierProductsAdd',
                params: {
                    supplierId: this.cloneProductSupplierId,
                },
                query: {
                    cloneProductConfigurationId: this.cloneProductConfigurationId,
                    cloneSupplierId: this.supplierId,
                },
            }
        },
    },
    created () {
        setTimeout(() => {
            const suppliersParams = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))
            suppliersParams.request.sortModel.push({
                colId: 'supplierName',
                sort: 'asc',
            })
            const suppliersUrl = CONFIG.API.ROUTES.SUPPLIERS.OVERVIEW
            this.addJob(suppliersUrl)
            this.$api.post(suppliersUrl, suppliersParams).then(({ data }) => {
                this.$set(this, 'allSuppliers', data.data.items)
            }).finally(() => {
                this.finishJob(suppliersUrl)
            })
        }, 2000)
        if (this.isVirtualSupplierManagement) {

        }
    },
}
</script>
